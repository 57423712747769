<template>
  <v-dialog max-width="300" persistent v-model="dialog" @click="hide">
    <template v-slot:activator="{on, attrs}">
      <button
          v-bind="attrs"
          v-on="on"
          :disabled="isRequest"
          class="button btn btn_seller elevation-2">
        <v-icon color="grey darken-3">mdi-human-dolly</v-icon>
        <span>Vendedor</span>
        <span class="shortkey">F4</span>
      </button>
    </template>
    <v-card>
      <v-card-title> Selecione um Vendedor!</v-card-title>
      <v-card-text>
        <v-autocomplete
          autofocus
          :items="sellers"
          clearable
          item-text="name"
          item-value="id"
          v-model="seller"
          label="Vendedor"></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="red darken-1" text @click="hide"> Fechar </v-btn>
        <v-btn color="info" @click="setSeller">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinSnack from "../_mixins/snackbar";

export default {
  name: "DialogSeller",
  props: ['isRequest'],
  mixins: [mixinSnack],
  data() {
    return {
      dialog: false,
      seller: null,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
    setSeller() {
      if (!this.seller) {
        this.snackError("Selecione um Vendedor!");
        return;
      }

      const seller = this.sellers.find((item) => item.id === this.seller);
      this.$store.commit('addSeller', seller)
      this.seller = null;
      this.hide();
    },
  },
  computed: {
    sellers() {
      return this.$store.state.seller.sellers || []
    },
  },
};
</script>

<style scoped></style>
