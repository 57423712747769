<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="modal"
    persistent
    width="500px">
    <template v-slot:activator="{ on, attrs }">
      <button
        class="button btn bg-gradient elevation-2"
        v-bind="attrs"
        v-on="on">
        <v-icon>mdi-text-box-search</v-icon>
        <span>Pesquisar Venda</span>
        <span class="shortkey">F8</span>
      </button>
    </template>
    <v-card>
      <v-card-title class="text-h5 blue-grey teal--text text--lighten-5">
        Edição
      </v-card-title>

      <v-card-text class="pt-5">
        <v-autocomplete
          :items="clients"
          item-value="id"
          item-text="name"
          v-model="client"
          @change="getSales"
          label="Cliente"
          clearable></v-autocomplete>
        <v-data-table
          dense
          :headers="headers"
          :items="sales"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event">
          <template v-slot:item.date="props">
            {{ props.item.date | moment("DD/MM/YYYY hh:mm:ss") }}
          </template>

          <template v-slot:item.client.name="props">
            {{ props.item.client.name.trim().split(" ")[0] }}
          </template>

          <template v-slot:item.nfe="props">
            <v-edit-dialog
              :return-value.sync="props.item.nfe"
              @save="saveSale(props.item)">
              <v-tooltip left color="info" transition="slide-x-transition">
                <template v-slot:activator="{attrs, on}">
                  <span v-bind="attrs" v-on="on" v-if="!props.item.nfe" class="grey--text">
                    Clique aqui!
                  </span>
                  <span v-bind="attrs" v-on="on" v-else>{{ props.item.nfe }}</span>
                </template>
                <span>Clique para editar a NF-e!</span>
              </v-tooltip>
              <template v-slot:input>
                <v-text-field
                  v-model="props.item.nfe"
                  label="NF-e"
                  single-line
                  counter></v-text-field>
              </template>
            </v-edit-dialog>
          </template>
        </v-data-table>
        <div class="text-center pt-2" v-if="pageCount > 1">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-end">
        <v-btn
          elevation="2"
          color="blue-grey teal--text text--lighten-5"
          @click="close">
          Sair
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import mixinSnack from "../../_mixins/snackbar";

export default {
  mixins: [mixinSnack],
  data() {
    return {
      modal: false,
      client: null,
      sales: [],
      resolve: null,
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
    };
  },
  beforeMount() {
    this.clear();
  },
  methods: {
    open() {
      return new Promise((resolve) => {
        this.modal = true;
        this.resolve = resolve;
      });
    },
    async getSales() {
      this.sales = [];
      if (+this.client) {
        let resp = await this.$axios(`/sale-client/${this.client}`);
        this.sales = resp.data || [];
      }
    },
    close() {
      this.modal = false;
      this.clear();
      this.resolve && this.resolve();
    },
    async saveSale(sale) {
      const { id, nfe } = sale;
      if (id.trim() && nfe.trim()) {
        let resp = await this.$axios.post(`/sale-client`, { id, nfe });
        if (resp.status == 500) {
          console.log(resp.data);
          return;
        }
        this.snackSuccess("NF-e alterada com sucesso!");
      }
    },
    clear() {
      this.sales = [];
      this.client = null;
    },
  },
  computed: {
    ...mapGetters({ clients: "client/clients" }),
    headers() {
      return [
        { text: "Data", value: "date" },
        { text: "Nome", value: "client.name" },
        { text: "NF-e", value: "nfe" },
      ];
    },
  },
};
</script>

<style>
</style>