import { render, staticRenderFns } from "./Chip.vue?vue&type=template&id=451d1073&scoped=true&"
import script from "./Chip.vue?vue&type=script&lang=js&"
export * from "./Chip.vue?vue&type=script&lang=js&"
import style0 from "./Chip.vue?vue&type=style&index=0&id=451d1073&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.7_cache-loader@4.1.0_css-loader@3.6.0_vue-template-compiler@2.6.12_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "451d1073",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.2_vue-template-compiler@2.6.12_vuetify@2.5.0_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VBtn,VIcon,VTooltip})
