<template>
  <v-dialog persistent v-model="dialog" @click="hide">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="button btn btn_request elevation-2"
      >
        <v-icon>mdi-text-box-search</v-icon>
        <span>Pesquisar Consignado</span>
        <span class="shortkey">F6</span>
      </button>
    </template>
    <v-card>
      <v-card-title> Consultar Consignados</v-card-title>
      <v-card-text>
        <div class="d-flex align-center">
          <v-autocomplete
            :items="clients"
            clearable
            item-text="name"
            item-value="id"
            v-model="client"
            label="Cliente"
            @click:clear="requests = []"
          ></v-autocomplete>
          <v-btn class="float-left ml-5" @click="findRequests">Buscar</v-btn>
        </div>
        <v-simple-table>
          <thead>
            <tr>
              <th>#</th>
              <th>Client</th>
              <th colspan="2">Data</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(r, i) in requests" :key="i">
              <td>{{ r.id }}</td>
              <td>{{ r.client.name }}</td>
              <td>{{ r.date | moment("DD/MM/YYYY") }}</td>
              <td class="text-center">
                <!-- prettier-ignore -->
                <v-btn v-if="r.status !== 'V'" x-small color="primary" @click="setRequestAct(r.id)">
                  Selecionar
                </v-btn>
                <!-- prettier-ignore -->
                <v-btn :to="{ name: 'sale-payment-request', params: { request_id: r.id } }" v-else
                  x-small>Relatório</v-btn>
              </td>
            </tr>
            <tr v-if="loadingTable">
              <td colspan="4" class="grey--text text-center">
                <v-progress-linear
                  indeterminate
                  color="cyan"
                ></v-progress-linear>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td
                v-if="!requests.length && !loadingTable"
                colspan="4"
                class="text-center grey--text"
              >
                Nenhum registro até o momento!
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="red darken-1" text block @click="hide"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinSnack from "../_mixins/snackbar";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "DialogRequest",
  props: ["isRequest"],
  mixins: [mixinSnack],
  data() {
    return {
      dialog: false,
      client: null,
      requests: [],
      request: null,
      loadingTable: false,
    };
  },
  methods: {
    ...mapActions(["setRequest", "clear"]),
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
    findRequests() {
      if (!this.client) {
        this.snackWarning("Selecione um cliente antes!");
        return;
      }
      this.loadingTable = true;
      this.requests = [];
      const { client } = this;
      this.$axios
        .get(`/request/client/${client}`)
        .then(({ data }) => {
          this.requests = data;
        })
        .catch(() => this.snackError("Erro ao obter os Consignados!"))
        .finally(() => (this.loadingTable = false));
    },
    setRequestAct(id) {
      this.clear();
      this.setRequest(id);
      this.hide();
    },
  },
  computed: {
    ...mapGetters({ clients: "client/clients" }),
  },
};
</script>

<style scoped></style>
