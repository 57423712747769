<template>
  <v-dialog v-model="dialog" width="300">
    <v-card>
      <v-card-title class="text-h5 warning white--text">
        Devolução
      </v-card-title>

      <v-card-text class="pt-5">
        <v-text-field
          autofocus
          v-model="amount"
          label="Quantidade"
        ></v-text-field>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="check" color="warning" @click="send">Devolver</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ModalDevolution",
  data() {
    return {
      dialog: false,
      amount: 0,
      max: 10,
      item: null,
    };
  },
  methods: {
    ...mapActions(["minusOneProduct"]),
    open(item) {
      this.max = item.amount;
      this.item = item;
      this.dialog = true;
    },
    send() {
      if (!this.check) {
        const item = { ...this.item, amountDev: this.amount };
        this.minusOneProduct(item);
      }
      this.dialog = false;
    },
  },
  computed: {
    check() {
      return +this.amount > +this.max || +this.amount <= 0;
    },
  },
};
</script>

<style scoped></style>
