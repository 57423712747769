<template>
  <v-tooltip v-if="obj" bottom transition="scroll-y-transition">
    <template v-slot:activator="{ on, attr }">
      <v-alert
        elevation="2"
        class="ma-0"
        border="left"
        dense
        text
        v-on="on"
        v-bind="attr"
      >
        <template v-slot:prepend>
          <v-icon class="pr-2">{{ icon || null }}</v-icon>
        </template>
        <div class="box">
          <span class="content">
            <b class="label">{{ label }}:</b>
            <span class="text">{{ obj.name.trim().split(/\s/i)[0] }} </span>
          </span>
          <v-btn class="white red--text" icon x-small @click="$emit('action')">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </template>
    <span>{{ obj.name }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "Chip",
  props: ["obj", "icon", "label", "action", "colors"],
};
</script>

<style scoped>
.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
i.v-icon {
  color: #999999;
}
span.content {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
}
.label {
  font-size: 0.675em;
  color: #999;
}
.text {
  font-size: 0.975em;
  font-weight: 500;
  color: #444444;
}
</style>
