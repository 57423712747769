<template>
  <v-simple-table dense light class="elevation-2">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Data</th>
          <th class="text-left">Nome</th>
          <th class="text-left">Qtd.</th>
          <th class="text-left">Valor</th>
          <th class="text-left text-no-wrap">Sub. Total</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in products">
          <template v-if="item.devolutionItem">
            <tr v-for="dp in item.devolutionItem" :key="dp.id">
              <td class="text-center">
                {{ dp.date | moment("DD/MM/YYYY hh:mm:ss") }}
              </td>
              <td>{{ item.product.name }}</td>
              <td>{{ dp.amount }}</td>
              <td>{{ item.price | money }}</td>
              <td>{{ (dp.amount * item.price) | money }}</td>
            </tr>
          </template>
        </template>
        <template v-if="devolutionProducts.length">
          {{ /* eslint-disable-next-line */ }}
          <tr v-for="dp in devolutionProducts">
            <td class="text-center">
              {{ dp.date | moment("DD/MM/YYYY hh:mm:ss") }}
            </td>
            <td>{{ dp.product.name }}</td>
            <td>{{ dp.amount }}</td>
            <td>{{ dp.price | money }}</td>
            <td>{{ (dp.amount * dp.price) | money }}</td>
          </tr>
        </template>
      </tbody>
      <tfoot v-if="!products.length">
        <tr>
          <td colspan="5" class="text-center">Nenhum produto até o momento!</td>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TableProductsDevolution",
  computed: {
    ...mapGetters(["products", "request", "devolutionProducts"]),
    isRequest() {
      return this.request != null;
    },
  },
};
</script>

<style scoped></style>
