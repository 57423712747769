<template>
  <div>
    <ModalDevolution ref="mdlDevolution" />
    <v-simple-table dense light class="elevation-2">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nome</th>
            <th class="text-left">Qtd.</th>
            <th class="text-left">Valor</th>
            <th class="text-left text-no-wrap">Sub. Total</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in products" :key="i">
            <td>{{ item.product.name }}</td>
            <td>
              <div class="d-flex align-center">
                <v-btn
                  v-if="item.amount > 0"
                  icon
                  color="red"
                  @click="minus(item)"
                >
                  -
                </v-btn>
                {{ item.amount }}
                <v-btn
                  v-if="!isRequest"
                  icon
                  color="green"
                  @click="moreOneProduct(item)"
                >
                  +
                </v-btn>
              </div>
            </td>
            <td>{{ item.price | money }}</td>
            <td>{{ (item.amount * item.price) | money }}</td>
            <td>
              <v-tooltip
                v-if="!isRequest"
                left
                transition="scroll-y-transition"
              >
                <template v-slot:activator="{ on, attr }">
                  <v-btn
                    icon
                    x-small
                    color="red"
                    v-on="on"
                    v-bind="attr"
                    @click="delProduct(item)"
                  >
                    <v-icon>mdi-delete-empty</v-icon>
                  </v-btn>
                </template>
                <span>Remover Produto</span>
              </v-tooltip>
            </td>
          </tr>
          <tr v-if="!products.length">
            <td colspan="5" class="text-center">Nenhum produto até o momento!</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5" class="text-right">
              <strong>Subtotal: </strong>{{ total | money }}
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ModalDevolution from "./ModalDevolution";

export default {
  name: "TableProducts",
  components: { ModalDevolution },
  methods: {
    ...mapActions(["minusOneProduct", "moreOneProduct", "delProduct"]),
    minus(item) {
      if (this.isRequest) {
        this.$refs.mdlDevolution.open(item);
      } else {
        this.minusOneProduct(item);
      }
    },
  },
  computed: {
    ...mapGetters(["products", "request", "total"]),
    isRequest() {
      return this.request != null;
    },
  },
};
</script>

<style scoped>
tbody > tr:nth-child(odd) > td {
  background-color: #f0f0f0;
}
</style>
