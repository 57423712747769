<template>
  <v-card>
    <v-card-title class="info white--text">
      <h4>Vamos Vender ;)</h4>
    </v-card-title>
    <v-card-text class="form">
      <v-autocomplete
        class="two-colums"
        :items="list"
        :filter="customFilter"
        autofocus
        clearable
        ref="product"
        item-text="name"
        item-value="id"
        v-model="product"
        @input="selectProduct"
        @click:clear="clearForm"
        label="Código / Produto"
      >
        <template v-slot:item="{ item }">
          <v-avatar class="mr-3" color="grey lighten-2">
            <img
              @error="errorImage($event)"
              :src="base_url + '/storage/' + item.photo"
            />
          </v-avatar>
          <v-chip
            v-if="item.code"
            class="grey--text text--darken-1 mr-2 wrap"
            small
            >{{ item.code }}</v-chip
          >
          <div class="d-flex flex-column py-3">
            <span class="">
              {{ item.name }}
            </span>
            <span class="stock">
              <strong>Estoque: </strong> {{ item.stock }}
            </span>
          </div>
        </template>
      </v-autocomplete>
      <v-text-field label="Qtd.:" ref="amount" v-model="amount"></v-text-field>
      <v-text-field-money
        label="Preço Unitário:"
        ref="price"
        v-model="price"
        :properties="{
          prefix: 'R$',
          placeholder: ' ',
        }"
        :options="{
          locale: 'pt-BR',
          length: 11,
          precision: 2,
          empty: null,
        }"
      ></v-text-field-money>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn class="elevation-3" color="info" @click="addItem()">
        Adicionar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import mixinSnack from "./../../_mixins/snackbar";
import { config } from "../../enviroment";
import noImage from "../../assets/no-image.svg";

export default {
  name: "AddProduct",
  mixins: [mixinSnack],
  data: () => ({
    selected: null,
    product: null,
    amount: 1,
    price: 0,
  }),
  methods: {
    ...mapMutations(["addProduct"]),
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.code?.toLowerCase() || "";
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    selectProduct(value) {
      if (value != null) {
        this.selected = this.list.find((o) => o.id === value);
        this.price = parseFloat(this.selected.price_sale);
      }
    },
    addItem() {
      if (this.validateForm()) {
        const { selected, amount, price } = this;
        const item = this.list.find((i) => i.id === selected.id);
        const product = { product: item, amount, price };
        this.addProduct(product);
        this.clearForm();
      }
    },
    validateForm() {
      const { selected, amount, price } = this;
      const snack = { message: null, timeout: 1500 };
      if (selected == null || !selected.id) {
        snack.message = "Selecione um Produto";
        this.snackError(snack);
        this.$refs.product.focus();
        return false;
      }
      if (amount === "" || parseInt(amount) < 1) {
        snack.message = "Digite uma Quantidade Válida!";
        this.snackError(snack);
        this.$refs.amount.focus();
        return false;
      }
      if (price == null || parseFloat(price) <= 0) {
        snack.message = "Digite um Valor Válido!";
        this.snackError(snack);
        this.$refs.price.focus();
        return false;
      }
      return true;
    },
    clearForm() {
      this.selected = null;
      this.amount = 1;
      this.price = 0;
      this.product = null;
      this.$refs.product.focus();
    },
    errorImage(e) {
      e.target.src = noImage;
      e.target.style = "width: 24px; height: 24px;";
    },
  },
  computed: {
    ...mapGetters(["products", "request"]),
    ...mapGetters({
      list: "product/products",
    }),
    isRequest() {
      return this.request !== null;
    },
    base_url() {
      return config.BASE_URL;
    },
  },
};
</script>

<style scoped>
.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.two-colums {
  grid-column: span 2;
}

.stock strong,
.stock {
  color: #666;
  text-transform: uppercase;
  font-size: 0.775em;
}
</style>
