<template>
  <v-dialog max-width="300" persistent v-model="dialog" @click="hide">
    <template v-slot:activator="{ on, attrs }">
      <button
        v-bind="attrs"
        v-on="on"
        class="button btn btn_client elevation-2"
        :disabled="isRequest"
      >
        <v-icon color="grey darken-3">mdi-account</v-icon>
        <span>Cliente</span>
        <span class="shortkey">F2</span>
      </button>
    </template>
    <v-card>
      <v-card-title> Selecione um Cliente!</v-card-title>
      <v-card-text>
        <v-autocomplete
          autofocus
          :items="clients"
          clearable
          item-text="name"
          item-value="id"
          v-model="client"
          label="Cliente"
        ></v-autocomplete>
      </v-card-text>
      <v-card-actions class="justify-space-between">
        <v-btn color="red darken-1" text @click="hide"> Fechar </v-btn>
        <v-btn color="info" @click="setClient">Salvar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixinSnack from "../_mixins/snackbar";
export default {
  name: "DialogClient",
  props: ["isRequest"],
  mixins: [mixinSnack],
  data() {
    return {
      dialog: false,
      client: null,
    };
  },
  methods: {
    show() {
      this.dialog = true;
    },
    hide() {
      this.dialog = false;
    },
    async setClient() {
      if (!this.client) {
        this.snackError("Selecione um Cliente!");
        return;
      }

      const client = await this.$axios(`/client/${this.client}`);
      this.$store.commit("addClient", client.data);
      this.client = null;
      this.hide();
    },
  },
  computed: {
    clients() {
      return this.$store.state.client.clients || [];
    },
  },
};
</script>

<style scoped></style>
