<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="modal"
    persistent
    width="300px"
  >
    <v-card>
      <v-card-title class="text-h5 blue blue--text text--lighten-5">
        Aviso!
      </v-card-title>

      <v-card-text>
        <p class="mt-4">Deseja imprimir o relatório da Devolução?</p>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="d-flex justify-space-between">
        <v-btn @click="close">Não</v-btn>
        <v-btn
          elevation="2"
          color="blue-grey teal--text text--lighten-5"
          @click="send"
        >
          Sim
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogPrint",
  data() {
    return {
      modal: false,
      resolve: null,
    };
  },
  methods: {
    open() {
      return new Promise((resolve) => {
        this.modal = true;
        this.resolve = resolve;
      });
    },
    close() {
      this.modal = false;
      this.resolve && this.resolve(false);
    },
    send() {
      this.modal = false;
      this.resolve && this.resolve(true);
    },
  },
};
</script>

<style></style>
