<template>
  <v-container fluid class="bg elevation-5 fill-height align-start">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col v-if="!isRequest" cols="12" md="6" lg="6">
            <AddProduct ref="addProduct" />
          </v-col>
          <v-col cols="12" md="6" lg="6">
            <v-row class="mb-3">
              <div class="col-12 col-sm-6 ma-0">
                <Chip
                  label="Cliente"
                  icon="mdi-account"
                  :obj="client"
                  @action="delClient"
                />
              </div>
              <div class="col-12 col-sm-6">
                <Chip
                  label="Vendedor"
                  icon="mdi-human-dolly"
                  :obj="seller"
                  @action="delSeller"
                />
              </div>
            </v-row>
            <TableProducts />
            <div class="mt-5" v-if="isRequest">
              <h3 class="text-center grey--text">Produtos Devolvidos</h3>
              <TableProductsDevolution />
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-col class="d-flex flex-wrap" style="gap: 20px">
          <button
            class="button btn btn_pay elevation-2"
            :class="enabledPayment ? 'success' : null"
            :disabled="!enabledPayment"
            @click="goPayment"
          >
            <v-icon>mdi-cash-register</v-icon>
            <span>Pagar</span>
            <span class="shortkey">F1</span>
          </button>
          <DialogClient :is-request="isRequest" ref="dialogClient" />
          <DialogSeller :is-request="isRequest" ref="dialogSeller" />
          <button
            class="button btn btn_request elevation-2"
            :disabled="!enabledPayment || isRequest"
            @click="generateRequest"
          >
            <v-icon>mdi-receipt</v-icon>
            <span>Gerar Consignado</span>
            <span class="shortkey">F5</span>
          </button>
          <DialogRequest
            v-if="!isRequest"
            :is-request="isRequest"
            ref="dialogRequest"
          />
          <button
            v-if="isRequest"
            class="button btn btn_devolution elevation-2"
            @click="makeDevolution"
          >
            <v-icon>mdi-backup-restore</v-icon>
            <span>Efetuar Devolução</span>
            <span class="shortkey">F6</span>
          </button>
          <ModalNFE ref="modalNfe" />
          <button class="button btn btn_clear elevation-2" @click="clear">
            <v-icon>mdi-backspace</v-icon>
            <span>Reiniciar A Venda</span>
            <span class="shortkey">F10</span>
          </button>
        </v-col>
      </v-col>
      <v-col><DialogPrint ref="dialogPrint" /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import DialogClient from "../../components/DialogClient";
import DialogSeller from "../../components/DialogSeller";
import mixinSnack from "../../_mixins/snackbar";
import { mapMutations, mapGetters, mapActions } from "vuex";
import TableProducts from "./TableProducts";
import DialogRequest from "../../components/DialogRequest";
import TableProductsDevolution from "./TableProductsDevolution";
import AddProduct from "./AddProduct";
import Chip from "../../components/Chip";
import ModalNFE from "./ModalNFE.vue";
import DialogPrint from "./DialogPrint";

export default {
  components: {
    DialogPrint,
    Chip,
    AddProduct,
    TableProductsDevolution,
    DialogRequest,
    TableProducts,
    DialogSeller,
    DialogClient,
    ModalNFE,
  },
  mixins: [mixinSnack],
  beforeMount() {
    const { product, seller, client, payment } = this.$store.state;
    if (
      !product.products.length ||
      !client.clients.length ||
      !seller.sellers.length ||
      !payment.coins.length
    ) {
      this.$store.dispatch("loadAll");
    }
  },
  mounted() {
    this.loadBox();
    document.onkeydown = (e) => {
      // prettier-ignore
      ["F1", "F2", "F3", "F4", "F5", "F6", "F7", "F8", "F9", "F10", "F11", "F12"].forEach((i) => {
        if (e.key === i) e.preventDefault();
      });
    };
    document.onkeyup = (e) => {
      const { href } = document.location;
      if (href.split("/").includes("pdv")) {
        switch (e.key) {
          case "F1":
            this.goPayment();
            break;
          case "F2":
            this.openModalClient();
            break;
          case "F4":
            this.openModalSeller();
            break;
          case "F5":
            if (this.check()) {
              this.generateRequest();
            }
            break;
          case "F6":
            break;
          case "F8":
            this.$refs.modalNfe.open();
            break;
        }
      }
    };
  },
  methods: {
    ...mapMutations(["addProduct", "delClient", "delSeller", "clear"]),
    ...mapActions(["loadBox"]),
    openModalClient() {
      !this.isRequest && this.$refs.dialogClient.show();
    },
    openModalSeller() {
      !this.isRequest && this.$refs.dialogSeller.show();
    },
    generateRequest() {
      if (!this.isRequest) {
        const { products, client, seller } = this;
        this.$axios
          .post("/request", {
            client,
            seller,
            products,
          })
          .then(({ data }) => {
            if (!data.errors.length) {
              this.snackSuccess("Consignado Gerado com Sucesso!");
              this.clear();
              this.$router.push(
                `/app/reports/request/${data.success.request.id}`
              );
            } else {
              this.snackError("Erro ao Gerar o Consignado!");
              console.error(data.errors);
            }
          });
      }
    },
    async makeDevolution() {
      if (this.isRequest) {
        const { products, request, devolutionProducts } = this;
        let response = await this.$axios.post("/request/devolution", {
          ...request,
          products,
          devolutionProducts,
        });
        if (!response.data.errors.length) {
          this.snackSuccess("Consignado Alterado com Sucesso!");
          this.clear();
          let {
            data: {
              success: {
                request: { id },
              },
            },
          } = response;
          this.$store.dispatch("setRequest", id);
          if (await this.$refs.dialogPrint.open()) {
            await this.$router.push(`/app/reports/request/${id}`);
          }
        } else {
          this.snackError("Erro ao Gerar o Consignado!");
          console.error(response.data.errors);
        }
      }
    },
    goPayment() {
      if (this.check()) {
        this.$router.push("payment");
      }
    },
    check() {
      if (!this.client) {
        this.snackWarning("Selecione um Cliente!");
        return false;
      }
      if (!this.seller) {
        this.snackWarning("Selecione um Vendedor!");
        return false;
      }
      if (!this.products.length) {
        this.snackWarning("Adicione pelo menos 1 Produto!");
        return false;
      }
      return true;
    },
    clear() {
      this.$store.dispatch("clear");
      if (this.$refs.addProduct) this.$refs.addProduct.clearForm();
    },
  },
  computed: {
    ...mapGetters([
      "client",
      "products",
      "seller",
      "request",
      "devolutionProducts",
      "checkBox",
    ]),
    enabledPayment: function () {
      if (this.client && this.seller && this.products.length >= 1) {
        return true;
      }
      return false;
    },
    isRequest() {
      return this.request != null;
    },
  },
};
</script>

<style>
.bg {
  background-color: rgba(245, 245, 245, 0.96);
  border-radius: 4px;
}

.btn {
  background: linear-gradient(#cccccc, #999999);
  color: #333333;
  border-radius: 4px;
}

.btn i::before {
  color: #eee;
}

.btn_pay {
  background: linear-gradient(#81c784, #43a047);
  color: #eee;
}

.btn_client {
  background: linear-gradient(#cfd8dc, #90a4ae);
  color: #444;
}
.btn_client i::before,
.btn_seller i::before {
  color: #444;
}

.btn_seller {
  background: linear-gradient(#b2dfdb, #4db6ac);
  color: #444;
}

.btn_request {
  background: linear-gradient(#ffb74d, #fb8c00);
  color: #eee;
}

.btn_devolution {
  background: linear-gradient(#5e35b1, #311b92);
  color: #eee;
}

.bg-gradient {
  background: linear-gradient(#546e7a, #263238);
  color: #eee;
}

.btn_clear {
  background: linear-gradient(#ff7043, #dd2c00);
  color: #eee;
}

.btn:disabled {
  background: none;
}
.btn:disabled i::before {
  color: #999;
}

.button {
  position: relative;
  padding: 10px;
  min-width: 120px;
  max-width: 120px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  overflow: hidden;
  transition: all ease-in-out 200ms;
}

.button:hover {
  opacity: 0.8;
  transform: scale(1.1);
}

.button i {
  font-size: 2em;
}

.button:disabled,
.button:disabled i {
  background-color: #cccccc !important;
  color: #999 !important;
}

.button span {
  font-size: 0.875em;
  font-weight: 500;
  text-transform: uppercase;
}

.button .shortkey {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0 4px 0 4px;
  padding: 3px 7px;
  font-size: 0.675em;
  font-weight: 400;
  color: whitesmoke;
  background: rgba(0, 0, 0, 0.4);
  transition: all 300ms ease-in-out;
}

.button .shortkey:hover {
  top: 4px;
  right: 5px;
  border-top-right-radius: 2px;
  transform: scale(1.5);
}

@media (max-width: 472px) {
  .button {
    min-width: 100%;
    max-width: 100%;
    min-height: auto;
    flex-direction: row;
    justify-content: start;
  }

  .button:hover {
    transform: scale(1.023);
  }

  .button i {
    font-size: 1.375em;
  }

  .button span {
    padding-left: 10px;
  }
}
</style>
