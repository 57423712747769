var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',{staticClass:"info white--text"},[_c('h4',[_vm._v("Vamos Vender ;)")])]),_c('v-card-text',{staticClass:"form"},[_c('v-autocomplete',{ref:"product",staticClass:"two-colums",attrs:{"items":_vm.list,"filter":_vm.customFilter,"autofocus":"","clearable":"","item-text":"name","item-value":"id","label":"Código / Produto"},on:{"input":_vm.selectProduct,"click:clear":_vm.clearForm},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"mr-3",attrs:{"color":"grey lighten-2"}},[_c('img',{attrs:{"src":_vm.base_url + '/storage/' + item.photo},on:{"error":function($event){return _vm.errorImage($event)}}})]),(item.code)?_c('v-chip',{staticClass:"grey--text text--darken-1 mr-2 wrap",attrs:{"small":""}},[_vm._v(_vm._s(item.code))]):_vm._e(),_c('div',{staticClass:"d-flex flex-column py-3"},[_c('span',{},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('span',{staticClass:"stock"},[_c('strong',[_vm._v("Estoque: ")]),_vm._v(" "+_vm._s(item.stock)+" ")])])]}}]),model:{value:(_vm.product),callback:function ($$v) {_vm.product=$$v},expression:"product"}}),_c('v-text-field',{ref:"amount",attrs:{"label":"Qtd.:"},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('v-text-field-money',{ref:"price",attrs:{"label":"Preço Unitário:","properties":{
        prefix: 'R$',
        placeholder: ' ',
      },"options":{
        locale: 'pt-BR',
        length: 11,
        precision: 2,
        empty: null,
      }},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"elevation-3",attrs:{"color":"info"},on:{"click":function($event){return _vm.addItem()}}},[_vm._v(" Adicionar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }